import $ from 'jquery';

let items = $('.content-bottom .child')
let indexServiceSelected = 0
let maxIndex = items.length - 1
let serviceImages = $('.service-image')
let contents = $('.content-top .right .service-content')
let onChangeService = false
let intervalSwitchService
let popupOverlay = $('#popupOverlay')
let popup = $('#popup')
let popupContents = $('.popup-content')
let logo = $('.logo-beelieve')
let menuItems = $('.mobile .menu-service .item')

const urlParams = new URLSearchParams(window.location.search);
const skipLoading = urlParams.get('skip-loading')

const containerLoading = $('#containerLoading')
if (skipLoading == 1) {
    containerLoading.attr("style", "display:none")
} else {
    const loadingPercentage = $('#loadingPercentage')
    let progress = 0
    const loadingInterval = setInterval(() => {
        progress += 1
        loadingPercentage.text(`${progress}%`)

        if (progress >= 100) {
            clearInterval(loadingInterval);

            setTimeout(() => {
                containerLoading.addClass('done')
                $('#cardContainer').addClass('show')
                setTimeout(() => {
                    containerLoading.attr("style", "display:none")
                }, 1000)
            }, 500)

        }
    }, 15)
}

$('#buttonInformation').click(function () {
    showPopup(indexServiceSelected)
})

$('#closePopup').click(function () {
    hidePopup()
})

$('#hidePopupOverlay').click(function () {
    hidePopup()
})

function showPopup(index) {
    clearInterval(intervalSwitchService)
    $(popupContents[index]).addClass('selected')
    popupOverlay.addClass('show')
    popup.addClass('show')
}

function hidePopup() {
    popup.removeClass('show')
    setTimeout(function () {
        popupOverlay.removeClass('show')
        popupContents.removeClass('selected')
        setIntervalSwitchService()
    }, 500)
}

function itemClick(target, isManual = false) {
    let index = $(target).index()
    if (onChangeService || index === indexServiceSelected) {
        return
    }
    if (isManual) {
        clearInterval(intervalSwitchService)
    }

    onChangeService = true
    indexServiceSelected = index
    items.removeClass('selected')
    $(target).addClass('selected')

    contents.removeClass('selected')
    $(contents[index]).addClass('selected')

    let selectedImageService = $('.service-image.selected')
    selectedImageService.addClass('blur').removeClass('selected')
    setTimeout(function () {
        $(serviceImages[index]).addClass('selected').removeClass('blur')
        onChangeService = false
        if (isManual) {
            setIntervalSwitchService()
        }
    }, 500)
}

items.click(function () {
    itemClick(this, true)
})

setIntervalSwitchService()

function setIntervalSwitchService() {
    intervalSwitchService = setInterval(function () {
        let index = indexServiceSelected + 1
        if (index > maxIndex) {
            index = 0
        }
        itemClick($(items[index]), false)
    }, 10000)
}

menuItems.click(function () {
    let value = $(this).attr("data-select");
    window.location.href = "service.html?service=" + value
})

$(".hamburger").click(function () {
    $('#hamburger-1').toggleClass("is-active")
    $('#overlayContactUsPanel').toggleClass('active')
});

$("#overlayContactUsPanel").click(function () {
    $('#hamburger-1').toggleClass("is-active")
    $('#overlayContactUsPanel').toggleClass('active')
});

$('#buttonPolicy').click(function () {
    let isMobile = $('#mainMobile').is(':visible');
    if (isMobile) {
        window.location.href = "service.html?service=" + (popupContents.length - 1)
    } else {
        showPopup(popupContents.length - 1)
    }
})